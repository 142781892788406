var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"team"},[_c('v-card',{staticClass:"white pa-3"},[_c('h1',{staticClass:"text-center mb-1 subtitle-4 black--text"},[_vm._v(" حذف طلاب من السائق ")]),_c('h2',{staticClass:"text-center subtitle-5 black--text"},[_vm._v(" ("+_vm._s(_vm.$route.params.name)+") ")]),_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('div',{staticClass:"d-md-flex"},[_c('v-btn',{staticClass:"white--text ml-2 mb-4 mb-md-0 mb-sm-0",attrs:{"tile":"","color":"#FF5252","block":_vm.isScreenXs},on:{"click":_vm.deleteStudentToDriver}},[_c('span',[_vm._v("حذف")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fa-minus ")])],1),_c('v-btn',{attrs:{"tile":"","color":"success","block":_vm.isScreenXs,"loading":_vm.xlsxData.downloadLoading},on:{"click":_vm.handleDownload}},[_vm._v(" تحميل اكسل "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fa-download ")])],1)],1)]),_c('v-spacer'),_c('v-col',{attrs:{"md":"3","sm":"3","xs":"12"}},[_c('v-text-field',{attrs:{"label":"البحث","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"show-select":"","loading":_vm.table.loading,"loading-text":"جاري التحميل ... الرجاء الانتظار","headers":_vm.headers,"items":_vm.driversData,"search":_vm.table.search,"items-per-page":10,"item-key":"_id","footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-plus',
            nextIcon: 'mdi-minus',
          }},scopedSlots:_vm._u([{key:"item.account_division_current",fn:function(ref){
          var item = ref.item;
return [(item.class_name)?_c('span',[_vm._v(_vm._s(item.class_name + " (" + item.leader + ")"))]):_c('span',[_vm._v("--")])]}}]),model:{value:(_vm.table.selectedStudent),callback:function ($$v) {_vm.$set(_vm.table, "selectedStudent", $$v)},expression:"table.selectedStudent"}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v(" هل انت متأكد من حذف العنصر ؟ ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v(" الغاء ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.deleteStudentLoading},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" حذف ")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogData.open),callback:function ($$v) {_vm.$set(_vm.dialogData, "open", $$v)},expression:"dialogData.open"}},[_c('v-toolbar',{attrs:{"color":_vm.dialogData.color,"dense":""}}),_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v(" "+_vm._s(_vm.dialogData.bodyText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogData.open = false}}},[_vm._v(" تم ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }